import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useOrderConfirmationPageQuery } from '../../generated/graphql';
import { OrderConfirmation } from '../../rs-emd-ui-modules/src/components/basket-purchase/order-confirmation';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { basketService } from '../../services/basket-service';
import { productService } from '../../services/product-service';

type OrderConfirmationWrapperProps = {
  contextInfo: IContextInfo;
};

export const OrderConfirmationWrapper: FunctionComponent<OrderConfirmationWrapperProps> = (props: OrderConfirmationWrapperProps) => {
  const { loading, error, data } = useOrderConfirmationPageQuery({
    ssr: true,
    fetchPolicy: 'network-only',
  });

  let location = useLocation();
  const isGuestOrFirstTimeUser = Boolean(JSON.parse(new URLSearchParams(location.search).get('guest') ?? '1'));

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.orderConfirmationPage.isSuccess || !data.orderConfirmationPage.details) {
    window.location.href = '/order/processing'; // external url
    return <></>;
  }

  return (
    <OrderConfirmation
      locale={props.contextInfo.locale}
      data={data.orderConfirmationPage.details}
      isGuestOrFirstTimeUser={isGuestOrFirstTimeUser}
      basketService={basketService}
      productService={productService}
    />
  );
};

export default OrderConfirmationWrapper;
