import React, { FunctionComponent } from 'react';
import {
  OrderConfirmationPageContactDetails,
  OrderDetailsPage_BillingAddress,
  OrderDetailsPage_DeliveryAddress,
  OrderDetailsPage_PaymentOption,
} from '../../../../../../../../generated/graphql';
import { getLabel } from '../../../../../../helpers/html.utils';
import { ILabel } from '../../../../../../models/common/label.model';
import { IContextInfo } from '../../../../../../models/master/context-info.model';
import styles from './delivery-details-component.module.scss';

export type DeliveryDetailsProps = {
  contextInfo: IContextInfo;
  labels?: ILabel[];
  orderNum: string;
  contactDetails: OrderConfirmationPageContactDetails;
  isDeliveryAddressSameAsBillingAddress: boolean;
  deliveryAddress: OrderDetailsPage_DeliveryAddress;
  invoiceAddress?: OrderDetailsPage_BillingAddress | null;
  paymentMethod?: OrderDetailsPage_PaymentOption | null;
  isControlledPurchasing?: boolean;
};

export const DeliveryDetails: FunctionComponent<DeliveryDetailsProps> = (props: DeliveryDetailsProps) => {
  function getInvoiceField(label: string, value: any, optional: boolean = false) {
    if (!optional || (optional && value)) {
      return (
        <div className={styles['invoice-field']}>
          <span className={styles.bold}>{label}:</span>
          <span>{value}</span>
        </div>
      );
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles['delivery-details-container']}>
        <div className={styles['address-container']}>
          <span className={styles.title}>{getLabel(props.labels, 'delivery_address')}</span>
          <div className={styles['address-wrapper']}>
            <div className={styles['contact-details']}>
              <span className={styles.bold}>
                {props.contactDetails.firstName} {props.contactDetails.lastName}
              </span>
              <span>{props.contactDetails.email}</span>
              <span>{props.contactDetails.phoneNo}</span>
            </div>
            <div className={styles.address}>
              {props.deliveryAddress.formattedMultiLine
                .filter((l) => l.length > 0)
                .map((l) => (
                  <p>{l}</p>
                ))}
            </div>
          </div>
        </div>
        {!props.isControlledPurchasing && (
          <div className={styles['address-container']}>
            <span className={styles.title}>{getLabel(props.labels, 'invoice_address')}</span>
            {props.invoiceAddress &&
              (props.isDeliveryAddressSameAsBillingAddress ? (
                <span>{getLabel(props.labels, 'same_as_delivery_address')}</span>
              ) : (
                <div className={styles['invoice-address']}>
                  {props.invoiceAddress.formattedMultiLine
                    .filter((l) => l.length > 0)
                    .map((l) => (
                      <p>{l}</p>
                    ))}
                </div>
              ))}
            {getInvoiceField(getLabel(props.labels, 'order_no_full'), props.orderNum)}
            {getInvoiceField(getLabel(props.labels, 'payment_method'), props.paymentMethod?.title)}
            {props.invoiceAddress?.details && (
              <div className={styles['india-fields']}>
                {getInvoiceField(getLabel(props.labels, 'GST_type'), props.invoiceAddress.details.gstCustomerType, true)}
                {getInvoiceField(getLabel(props.labels, 'GST_registration_type'), props.invoiceAddress.details.gstRegistrationType, true)}
                {getInvoiceField(getLabel(props.labels, 'GST_registration_num'), props.invoiceAddress.details.gstRegistrationNumber, true)}
                {getInvoiceField(getLabel(props.labels, 'PAN_number'), props.invoiceAddress.details.panCardNumber, true)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
