import React, { FunctionComponent, useCallback, useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, CircularLoader, Dropdown, useDebouncedCallback } from 'rs-emd-ui-atoms';
import { DropdownRef } from 'rs-emd-ui-atoms/dist/components/form-fields/dropdown-component/dropdown-component';
import { useUserDispatch } from '../../../../../../../../components/app/user-context';
import { basketService } from '../../../../../../../../services/basket-service';
import { pricingService } from '../../../../../../../../services/pricing-service';
import { productService } from '../../../../../../../../services/product-service';
import { clickEvent } from '../../../../../../../../tagging/ensighten';
import { getLabel } from '../../../../../../helpers/html.utils';
import { Validation } from '../../../../../../helpers/validation.utils';
import { EnergyLabelProductInfo } from '../../../../../shared';
import styles from './order-summary-item-component.module.scss';
import { Action, OrderSummaryItemAction, OrderSummaryItemProps, State } from './order-summary-item.model';

export const OrderSummaryItem: FunctionComponent<OrderSummaryItemProps> = (props: OrderSummaryItemProps) => {
  let dropdownRef = useRef<DropdownRef>(null);
  const userDispatch = useUserDispatch();
  const searchUnlistedQuantity = useDebouncedCallback((numVal) => {
    dispatch({
      type: OrderSummaryItemAction.SearchUnlistedQuantity,
      quantity: numVal,
    });
  }, 300);

  const [state, dispatch] = useReducer(reducer, {
    quantity: props.parcelItem.quantity,
    prices: [],
    minimumOrderQuantity: props.parcelItem.product.priceArea.minimumOrderQuantity,
    isValidQty: (props.parcelItem.product.priceArea.errorMessage ?? '') === '',
    errorMessage: props.parcelItem.product.priceArea.errorMessage,
    isDropdownOpen: false,
    defaultDropdownSelection: props.parcelItem.quantity,
    lastPropQty: props.parcelItem.quantity,
    isBusy: false,
    isResetting: false,
    latestSelectedQuantity: props.parcelItem.quantity,
  });

  let showIGST = props.parcelItem.product.priceArea.taxType?.filter((t) => t.includes('GST')).length > 0;

  //useCallback required to run only when state changes & when fn is called
  const getDropDownValues = useCallback(() => {
    if (state.prices.length > 0) {
      if (!state.isSearchingUnlistedQuantity) {
        return state.prices.map((p) => {
          return {
            key: p.quantity,
            elt: (
              <div className={styles['qty-opt']}>
                <span>{p.quantity}</span>
                <span className={styles['qty-price']}>{p.totalPrice.priceAfter?.displayValue}</span>
              </div>
            ),
            searchableString: p.quantity.toString(),
          };
        });
      } else {
        // if errormessage - return empty array
        if (!state.isValidQty) return [];
        else
          return [
            {
              key: state.quantity,
              elt: (
                <div className={styles['qty-opt']}>
                  <span>{state.quantity}</span>
                  {state.unlistedQuantityPrice ? (
                    <span className={styles['qty-price']}> {state.unlistedQuantityPrice}</span>
                  ) : (
                    <CircularLoader />
                  )}
                </div>
              ),
              searchableString: state.quantity.toString(),
            },
          ];
      }
    } else {
      return [
        {
          key: state.quantity,
          elt: (
            <div className={styles['qty-opt']}>
              <span>{state.quantity}</span>
              {state.isValidQty ? (
                <span className={styles['qty-price']}>{props.parcelItem.product.priceArea.totalPrice.priceAfter?.displayValue}</span>
              ) : (
                <CircularLoader />
              )}
            </div>
          ),
          searchableString: state.quantity.toString(),
        },
      ];
    }
  }, [props.parcelItem, state.isSearchingUnlistedQuantity, state.isValidQty, state.prices, state.quantity, state.unlistedQuantityPrice]);

  //get price for quantity not listed in dropdown values
  useEffect(() => {
    let getPricesByQtyTimeout = setTimeout(() => {
      if (state.isSearchingUnlistedQuantity) {
        //query to get price for quantity not listed in dropdown values then set price
        pricingService.getPricesByQty({ stockCode: props.parcelItem.product.title.stockCode, quantity: state.quantity }).then((res) => {
          dispatch({
            type: OrderSummaryItemAction.SetUnlistedQuantityPrice,
            price:
              res.priceArea?.pricesExVat && res.priceArea?.pricesExVat.length > 0
                ? res.priceArea.pricesExVat[0].totalPrice.priceAfter?.displayValue
                : res.priceArea?.prices && res.priceArea?.prices.length > 0
                ? res.priceArea.prices[0].totalPrice.priceAfter?.displayValue
                : undefined,
            message: res.priceArea && res.priceArea.errorMessage.length > 0 ? res.priceArea?.errorMessage : undefined,
          });
        });
      }
    }, 500);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(getPricesByQtyTimeout);
  }, [props.parcelItem.product.title.stockCode, state.isDropdownOpen, state.isSearchingUnlistedQuantity, state.quantity]);

  useEffect(() => {
    function onAddToBasketEvent() {
      const attributes: any = {
        __Type: 'buttonTracker',
        __Info: 'Add to basket from Orders',
        __Result: `${props.orderRef.toString()}, ${props.parcelItem?.product.title.stockCode}`,
      };

      clickEvent(attributes);
    }

    function addToBasket() {
      onAddToBasketEvent();

      if (state.isValidQty && !state.isBusy) {
        //cannot use state.isValidQty as it updates after this fn is called in the following scenario:
        //select (by clicking outside) invalid qty and then select (by clicking outside) valid qty in tablet+
        let validationRes = Validation.validateQuantity(state.minimumOrderQuantity, state.quantity.toString());

        if (validationRes.isValid && !state.isResetting && props.parcelItem) {
          dispatch({ type: OrderSummaryItemAction.AddToBasket, isBusy: true });

          basketService
            .addToBasket(
              {
                variables: { stockCode: props.parcelItem.product.title.stockCode, quantity: state.quantity },
                actionType: 'product',
              },
              userDispatch
            )
            .then((res) => {
              if (res.data?.addToBasketV2) {
                dispatch({
                  type: OrderSummaryItemAction.AddToBasket,
                  isBusy: false,
                  isDropdownOpen: false,
                  latestSelectedQty: state.quantity,
                });
                props.onAddToBasketCallback?.({ product: props.parcelItem, quantity: state.quantity });
              }
              dispatch({ type: OrderSummaryItemAction.SetIsAttemptingToAddToBasket, isAttemptingToAddToBasket: false });
            });
        }
      } else {
        dispatch({ type: OrderSummaryItemAction.SetIsAttemptingToAddToBasket, isAttemptingToAddToBasket: false });
      }

      if (state.isResetting) {
        dispatch({ type: OrderSummaryItemAction.ResetToLatestSelectedQty, isResetting: false });
      }
    }

    if (state.isAttemptingToAddToBasket) {
      // Small delay to allow dropdown to update
      let addToBasketTimeout = setTimeout(() => {
        addToBasket();
      }, 300);

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(addToBasketTimeout);
    }
  }, [
    props.onAddToBasketCallback,
    props.orderRef,
    props.parcelItem,
    state.isAttemptingToAddToBasket,
    state.isBusy,
    state.isResetting,
    state.isValidQty,
    state.minimumOrderQuantity,
    state.quantity,
    userDispatch,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles['product-details-container']}>
        {/* Product image */}
        <Link to={props.parcelItem.product.title.pdpUrl} className={styles['thumb-container']}>
          <img
            src={props.parcelItem.product.title.primaryImageUrl}
            alt={props.parcelItem.product.title.title}
            className={styles.thumb}
            onLoad={props.imageLoadedCallback}
          />
        </Link>

        <div className={styles['product-details']}>
          {/* Product Title*/}
          <Link to={props.parcelItem.product.title.pdpUrl}>
            <span className={styles['product-title']}>{props.parcelItem.product.title.title}</span>
          </Link>

          {/* Attributes */}
          <span>
            {getLabel(props.labels, 'stock_code')}: {props.parcelItem.product.title.stockCode}
          </span>
          <span>
            {getLabel(props.labels, 'brand')}:&nbsp;
            <a href={`/productlist/search?query=${props.parcelItem.product.title.brand.toLowerCase()}`} className={styles.brand}>
              {props.parcelItem.product.title.brand}
            </a>
          </span>
          {props.parcelItem.product.title.mpn.length > 0 && (
            <span>
              {getLabel(props.labels, 'mpn')}: {props.parcelItem.product.title.mpn}
            </span>
          )}
          {(props.parcelItem.product.title.impaCode ?? '').length > 0 && (
            <span>
              {getLabel(props.labels, 'impa')}: {props.parcelItem.product.title.impaCode}
            </span>
          )}
          {props.parcelItem.product.title.hsnCode && (
            <span>
              {getLabel(props.labels, 'hsn')}: {props.parcelItem.product.title.hsnCode}
            </span>
          )}
          {showIGST &&
            props.parcelItem.product.priceArea.taxType.map((taxType) => {
              return (
                <span key={taxType}>
                  {getLabel(props.labels, 'tax_type')}: {taxType}
                </span>
              );
            })}
          {props.parcelItem.product.title.partNumber && (
            <span>
              {getLabel(props.labels, 'your_part_number')}: {props.parcelItem.product.title.partNumber}
            </span>
          )}
          {props.parcelItem.product.energyArea && (
            <EnergyLabelProductInfo
              energyArea={props.parcelItem.product.energyArea}
              stockCode={props.parcelItem.product.title.stockCode}
              labels={props.labels}
              productService={productService}
            />
          )}
        </div>
      </div>
      <div className={styles['price-container']}>
        <div className={styles['quantity-container']}>
          <span className={styles.bold}>
            {getLabel(props.labels, 'quantity_ordered')}: {props.parcelItem.quantity}
          </span>
          {getPriceLine()}
        </div>

        <div className={`${styles['reorder-container']} ${props.isDownload ? styles.hide : ''}`}>
          <span className={styles['reorder-item']}>{getLabel(props.labels, 'reorder_item')}</span>
          <div className={styles['reorder-inputs']}>
            <Dropdown
              ref={dropdownRef}
              onSearchValueChange={(val) => {
                onSearchValueChange(val);
              }}
              onFocus={() => {
                //to reset list especially for mobile modal
                if (state.isSearchingUnlistedQuantity) {
                  dispatch({
                    type: OrderSummaryItemAction.SearchListedQuantity,
                  });
                }
              }}
              list={getDropDownValues()}
              background='grey'
              isOpen={state.isDropdownOpen}
              defaultSelection={
                getDropDownValues().find((v) => v.key === state.defaultDropdownSelection) ? state.defaultDropdownSelection : undefined
              }
              className={styles.qty}
              isSearchable={true}
              customSearchHeader={mobileSearchHeader()}
              customSearchHeaderClassName='search-header'
              searchableInputType='number'
              additionalInfo={`${getLabel(props.labels, 'available_in_multiples')} ${state.minimumOrderQuantity}`}
              isValid={state.isValidQty}
              searchableStepValue={state.minimumOrderQuantity}
              prioritiseMobileModalCloseIconClick={true}
              onChangeState={(isOpen) => onPricesDropdownToggle(isOpen)}
              minNumericValue={state.minimumOrderQuantity}
              validationCallback={validateQuantity}
              isDisabled={state.isAttemptingToAddToBasket}
              selectOnClickOutside={true}
              selectCallback={(quantity) => onSelection(Number(quantity))}
            />
            <Button
              text={getLabel(props.labels, 'add_to_basket')}
              onClick={() => dispatch({ type: OrderSummaryItemAction.SetIsAttemptingToAddToBasket, isAttemptingToAddToBasket: true })}
              disabled={!state.isValidQty || state.isAttemptingToAddToBasket}
              icon={state.isAttemptingToAddToBasket ? 'circular-loader' : undefined}
            />
          </div>
          {!state.isValidQty && state.errorMessage && state.errorMessage?.length > 0 && (
            <span className={styles.error}>{state.errorMessage}</span>
          )}
        </div>
      </div>
    </div>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case OrderSummaryItemAction.SetIsValidQty:
        return {
          ...state,
          isValidQty: action.isValid,
          quantity: action.quantity,
        };
      case OrderSummaryItemAction.AddPriceData: {
        let isSearchingUnlistedQuantity =
          action.data.productPrices?.filter((p) => p.quantity === state.quantity).length === 0 && state.quantity !== 0;
        return {
          ...state,
          prices: action.data.productPrices ?? [],
          isSearchingUnlistedQuantity: isSearchingUnlistedQuantity,
          defaultDropdownSelection: isSearchingUnlistedQuantity && !state.isValidQty ? undefined : state.defaultDropdownSelection,
        };
      }
      case OrderSummaryItemAction.ToggleDropDownOpen: {
        return {
          ...state,
          isDropdownOpen: action.isOpen,
          isSearchingUnlistedQuantity: state.prices.filter((p) => p.quantity === state.quantity).length === 0 && state.quantity !== 0,
          isResetting: action.isOpen ? false : state.isResetting,
        };
      }
      case OrderSummaryItemAction.SetUnlistedQuantityPrice: {
        let isValid = !(action.message && action.message !== '');
        return {
          ...state,
          unlistedQuantityPrice: action.price,
          isValidQty: isValid,
          errorMessage: action.message,
          defaultDropdownSelection: isValid ? state.defaultDropdownSelection : undefined, //else it defaults to set value
        };
      }
      case OrderSummaryItemAction.SearchUnlistedQuantity: {
        return {
          ...state,
          quantity: action.quantity,
          unlistedQuantityPrice: undefined,
          isValidQty: true, // temporarily set to true, pending query result
          isSearchingUnlistedQuantity: action.quantity !== 0,
          defaultDropdownSelection: undefined,
        };
      }
      case OrderSummaryItemAction.SearchListedQuantity: {
        return {
          ...state,
          isSearchingUnlistedQuantity: false,
          defaultDropdownSelection: undefined, //else it defaults to set value
          errorMessage: '',
          isValidQty: true,
        };
      }
      case OrderSummaryItemAction.ResetToLatestSelectedQty: {
        let isLatestSelectedValid = Validation.validateQuantity(state.minimumOrderQuantity, state.latestSelectedQuantity.toString())
          .isValid;

        if (action.isResetting) {
          return {
            ...state,
            isResetting: true,
            quantity: state.latestSelectedQuantity,
            isValidQty: isLatestSelectedValid,
            defaultDropdownSelection: state.latestSelectedQuantity,
            prices: isLatestSelectedValid ? state.prices : [],
            isSearchingUnlistedQuantity:
              state.prices.filter((p) => p.quantity === state.latestSelectedQuantity).length === 0 && state.latestSelectedQuantity !== 0,
          };
        } else {
          return {
            ...state,
            isResetting: false,
          };
        }
      }
      case OrderSummaryItemAction.SetIsAttemptingToAddToBasket: {
        return {
          ...state,
          isAttemptingToAddToBasket: action.isAttemptingToAddToBasket,
        };
      }
      case OrderSummaryItemAction.AddToBasket: {
        return {
          ...state,
          isBusy: action.isBusy,
          isDropdownOpen: action.isDropdownOpen ?? state.isDropdownOpen,
          latestSelectedQuantity: action.latestSelectedQty ?? state.latestSelectedQuantity, //to store latest selected quantity prior to opening mobile modal
        };
      }
    }
  }

  function onSearchValueChange(value: string) {
    let numVal = Number(value);

    //if unlisted quantity
    if (state.prices.map((p) => p.quantity.toString()).filter((p) => p.includes(value.toLowerCase())).length === 0) {
      searchUnlistedQuantity(numVal);
    }
    //if listed quantity / substring quantity (e.g. searching 70 when quantity must be multiple of 700)
    else if (state.isSearchingUnlistedQuantity) {
      dispatch({
        type: OrderSummaryItemAction.SearchListedQuantity,
      });
    }
  }

  function onPricesDropdownToggle(isOpen: boolean) {
    dispatch({ type: OrderSummaryItemAction.ToggleDropDownOpen, isOpen: isOpen });

    if (isOpen && !state.prices.length) {
      //query to get prices
      pricingService
        .getPrices({ stockCode: props.parcelItem.product.title.stockCode })
        .then((res) => dispatch({ type: OrderSummaryItemAction.AddPriceData, data: res }));
    }
  }

  function getPriceLine() {
    return (
      <div className={styles['price-table']}>
        <div className={styles['price-row']}>
          <span>{props.parcelItem.product.priceArea.unitPrice.priceBefore.displayValue}</span>
          <span className={styles.bold}>{props.parcelItem.product.priceArea.totalPrice.priceBefore.displayValue}</span>
        </div>
        <div className={styles['price-row']}>
          <span className={styles.desc}>{getLabel(props.labels, 'uom_ea')}</span>
          <span className={styles.desc}>{getLabel(props.labels, 'total')}</span>
        </div>
      </div>
    );
  }

  function mobileSearchHeader() {
    return (
      <div className={styles['modal-search-header']}>
        <span className={styles['modal-title']}>{getLabel(props.labels, 'select_quantity')}</span>
        <div className={styles.info}>
          <div className={styles['header-product-info']}>
            <Link to={props.parcelItem.product.title.pdpUrl} className={styles['modal-thumb-container']}>
              <img
                className={styles.thumb}
                src={props.parcelItem.product.title.primaryImageUrl}
                alt={props.parcelItem.product.title.title}></img>
            </Link>
            <div className={styles.details}>
              <Link to={props.parcelItem.product.title.pdpUrl}>
                <span className={styles.title}>{props.parcelItem.product.title.title}</span>
              </Link>
              {getPriceLine()}
            </div>
          </div>
        </div>

        <div className={styles['cta-area']}>
          <Button
            text={getLabel(props.labels, 'add_to_basket')}
            className={styles.add}
            disabled={!state.isValidQty || state.isBusy}
            onClick={() => {
              dropdownRef.current?.forceSelect();
              dispatch({ type: OrderSummaryItemAction.SetIsAttemptingToAddToBasket, isAttemptingToAddToBasket: true });
            }} // required since no selection is made if user immediately clicks button after typing
          />
        </div>
      </div>
    );
  }

  function validateQuantity(value: string): boolean {
    //timeout added to give time to toggle dropdown (in dropdown component) before changing state
    setTimeout(() => {
      let validationRes = Validation.validateQuantity(state.minimumOrderQuantity, value);

      //to get error message when invalid quantity is substring of listed quantities (e.g. searching 70 when quantity must be multiple of 700)
      if (
        state.prices.map((p) => p.quantity.toString()).filter((p) => p.includes(value.toLowerCase())).length > 0 &&
        !validationRes.isValid
      ) {
        dispatch({
          type: OrderSummaryItemAction.SearchUnlistedQuantity,
          quantity: Number(value),
        });
      }

      dispatch({
        type: OrderSummaryItemAction.SetIsValidQty,
        isValid: validationRes.isValid,
        quantity: Number(value),
      });

      return validationRes.isValid;
    }, 100);
    return true;
  }

  function onSelection(quantity: number) {
    if (quantity !== state.latestSelectedQuantity && !state.isBusy) {
      //cannot use state.isValidQty as it updates after this fn is called in the following scenario:
      //select (by clicking outside) invalid qty and then select (by clicking outside) valid qty in tablet+
      let validationRes = Validation.validateQuantity(state.minimumOrderQuantity, quantity.toString());

      if (validationRes.isValid && !state.isResetting) {
        //only to select quantity in dropdown - it is not actually added to basket
        dispatch({
          type: OrderSummaryItemAction.AddToBasket,
          isBusy: false,
          isDropdownOpen: false,
          latestSelectedQty: quantity,
        });
      }
    }
  }
};
