import { OrderDetailsParcelItem } from '../../../../../../../../generated/graphql';
import { ILabel } from '../../../../../../models/common/label.model';
import { IPricesResult } from '../../../../../../services/pricing/pricing.dto';
import { IBasketPricesItem } from '../../../../../basket-purchase/shared/product-item/product-item-component.model';

export type OrderSummaryItemProps = {
  labels?: ILabel[];
  orderRef: string;
  parcelItem: OrderDetailsParcelItem;
  isDownload?: boolean;
  imageLoadedCallback?: () => void;
  onAddToBasketCallback?: (item: { product: OrderDetailsParcelItem; quantity: number }) => void;
};

export type State = {
  quantity: number;
  prices: IBasketPricesItem[];
  unlistedQuantityPrice?: string;
  isSearchingUnlistedQuantity?: boolean;
  isAttemptingToAddToBasket?: boolean;
  isValidQty?: boolean;
  isDropdownOpen?: boolean;
  isResetting?: boolean;
  errorMessage?: string;
  defaultDropdownSelection?: number;
  minimumOrderQuantity: number;
  lastPropQty?: number; //to keep track when prop changes to update default selection
  isBusy: boolean;
  latestSelectedQuantity: number; //to store latest selected quantity prior to opening mobile modal
};

export enum OrderSummaryItemAction {
  SetIsValidQty = 'SetIsValidQty',
  AddToBasket = 'AddToBasket',
  SetIsAttemptingToAddToBasket = 'SetIsAttemptingToAddToBasket',
  ToggleDropDownOpen = 'ToggleDropDownOpen',
  SetUnlistedQuantityPrice = 'SetUnlistedQuantityPrice',
  SearchUnlistedQuantity = 'SearchUnlistedQuantity',
  SearchListedQuantity = 'SearchListedQuantity',
  ResetToLatestSelectedQty = 'ResetToLatestSelectedQty',
  AddPriceData = 'AddPriceData',
}

export type Action =
  | { type: OrderSummaryItemAction.SetIsValidQty; isValid: boolean; quantity: number }
  | { type: OrderSummaryItemAction.AddPriceData; data: IPricesResult }
  | { type: OrderSummaryItemAction.ToggleDropDownOpen; isOpen: boolean }
  | { type: OrderSummaryItemAction.AddToBasket; isBusy: boolean; isDropdownOpen?: boolean; latestSelectedQty?: number }
  | { type: OrderSummaryItemAction.SetUnlistedQuantityPrice; price?: string; message?: string }
  | { type: OrderSummaryItemAction.SearchUnlistedQuantity; quantity: number }
  | { type: OrderSummaryItemAction.SearchListedQuantity }
  | { type: OrderSummaryItemAction.ResetToLatestSelectedQty; isResetting: boolean }
  | { type: OrderSummaryItemAction.SetIsAttemptingToAddToBasket; isAttemptingToAddToBasket: boolean };
