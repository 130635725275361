import {
  BulkAddToBasketDocument,
  BulkAddToBasketMutation,
  BulkAddToBasketMutationVariables,
  BulkAddToBasketRequest,
  BulkAddToBasketResponse,
  OrderHistoryPage,
  OrderHistoryPageDocument,
  OrderHistoryPageQuery,
  OrderHistoryPageQueryVariables,
  OrderSort,
} from '../generated/graphql';
import { IPagination } from '../rs-emd-ui-modules/src/components/product-list-page/page-controls/pagination';
import { graphQLService } from './graphql-service';

export class MyAccountOrdersService {
  async getOrders(pagination: IPagination, sort?: OrderSort, searchQuery?: string): Promise<OrderHistoryPage | undefined> {
    const result = await graphQLService.apolloClient.query<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>({
      query: OrderHistoryPageDocument,
      variables: {
        sort: sort,
        page: { first: pagination.size, offset: pagination.offset },
        searchQuery: searchQuery,
      },
      fetchPolicy: 'cache-first',
    });

    let res: OrderHistoryPage | undefined = undefined;

    if (result.data) {
      res = result.data.orderHistoryPage as OrderHistoryPage;
    }

    return res;
  }

  async bulkAddToBasket(req: BulkAddToBasketRequest): Promise<BulkAddToBasketResponse> {
    const result = await graphQLService.apolloClient.mutate<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>({
      mutation: BulkAddToBasketDocument,
      variables: { data: { requestId: req.requestId, lines: req.lines } },
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result.data?.bulkAddToBasket as BulkAddToBasketResponse;
  }
}

export const myAccountOrdersService = new MyAccountOrdersService();
