import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Accordion, Button } from 'rs-emd-ui-atoms';
import { AccordionProps } from 'rs-emd-ui-atoms/dist/components/accordion/accordion-component';
import { ILabel } from '../../../../../../models/common/label.model';
import { getLabel } from '../../../../../../helpers/html.utils';
import styles from './details-accordion-component.module.scss';

type DetailsAccordionProps = {
  labels?: ILabel[];
} & AccordionProps;

export const DetailsAccordion: FunctionComponent<DetailsAccordionProps> = (props: PropsWithChildren<DetailsAccordionProps>) => {
  const { title, onAccordionToggle, headingWrapperClassname, headingClassName, ...accordionAttributes } = props;
  const [isOpen, setIsOpen] = useState(props.isOpenByDefault ?? false);

  const titleWithButton = (
    <>
      {title}
      {!props.disableToggle && <Button text={getLabel(props.labels, isOpen ? 'hide' : 'show')} buttonType='link' />}
    </>
  );

  return (
    <Accordion
      title={titleWithButton}
      onAccordionToggle={(isOpen) => {
        setIsOpen(isOpen);
        onAccordionToggle?.(isOpen);
      }}
      isOpenByDefault={isOpen}
      headingClassName={`${styles.heading} ${headingClassName ?? ''}`}
      headingWrapperClassname={`${styles['heading-wrapper']} ${headingWrapperClassname ?? ''}`}
      {...accordionAttributes}
    />
  );
};
