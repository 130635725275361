import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Button } from 'rs-emd-ui-atoms';
import { useUserDispatch } from '../../../../../../../components/app/user-context';
import { OrderDetailsPage, OrderDetailsParcelItem } from '../../../../../../../generated/graphql';
import { headerService } from '../../../../../../../services/header-service';
import { myAccountOrdersService } from '../../../../../../../services/my-account-orders-service';
import { clickEvent } from '../../../../../../../tagging/ensighten';
import { getLabel } from '../../../../../helpers/html.utils';
import { ReactComponent as Logo } from '../../../../../images/rs-logo.svg';
import { IBulkAddToBasketResponse } from '../../../../../models/basket/bulk-add-to-basket.model';
import { IContextInfo } from '../../../../../models/master/context-info.model';
import { BulkAddResultModal } from '../../../../basket-purchase/basket';
import { ConfirmationModal, MetaDataAndTagging } from '../../../../shared';
import { OrderList, OrderListOrder } from '../order-list/order-list-component';
import { DeliveryDetails } from './delivery-details/delivery-details-component';
import { DetailsAccordion } from './details-accordion/details-accordion-component';
import styles from './order-details-component.module.scss';
import { OrderSummaryItem } from './order-summary-item/order-summary-item-component';

type OrderDetailsProps = {
  contextInfo: IContextInfo;
  orderId: number;
  data: OrderDetailsPage;
  isDownload?: boolean;
};

export const OrderDetails: FunctionComponent<OrderDetailsProps> = (props: OrderDetailsProps) => {
  const location = useLocation();
  const userDispatch = useUserDispatch();

  let [isReorderingAll, setIsReorderingAll] = useState(false);
  let [bulkAddToBasketResponse, setBulkAddToBasketResponse] = useState<IBulkAddToBasketResponse | undefined>(undefined);
  let [currentProduct, setCurrentProduct] = useState<{ product: OrderDetailsParcelItem; quantity: number } | undefined>(undefined);
  let [numImagesLoaded, setNumImagesLoaded] = useState(0); // for downloading orders

  // check that all images are loaded for download
  useEffect(() => {
    if (props.isDownload && numImagesLoaded === props.data.details?.parcels.length) {
      window.print();
    }
  }, [numImagesLoaded, props.data.details, props.isDownload]);

  const backToOrdersButton = (
    <Button
      text={getLabel(props.data.labels, 'back_to_orders')}
      icon='chev-left'
      buttonType='link'
      href='/user/myaccount/orders'
      isExternalLink={false}
      className={styles['back-button']}
    />
  );

  return (
    <div className={`inner-page-wrapper ${styles['order-details-container']}`}>
      {/* Head data */}
      <MetaDataAndTagging tagging={props.data.tagging} metaData={props.data.metaData} />

      {/**Page header */}
      {props.isDownload && (
        <div className={styles.header}>
          <div className={`inner-page-wrapper ${styles['header-contents']}`}>
            {/**Logo */}
            <div className={styles['logo-wrapper']}>
              <Logo title='RS logo' width={44} />
            </div>

            <div className={styles['site-info']}>
              <span className={styles.site}>{props.data.header.siteName}</span>
              <span className={styles.address} dangerouslySetInnerHTML={{ __html: props.data.header.siteAddress }}></span>
            </div>
          </div>
        </div>
      )}

      {!props.isDownload && backToOrdersButton}
      {props.data.details?.paymentOption?.details?.controlledPurchasingDetails ? (
        <div className={styles['controlled-purchasing-title']}>
          <Badge colour='green'>{getLabel(props.data.labels, 'controlled_purchasing')}</Badge>
          <span>
            {`${getLabel(props.data.labels, 'controlled_purchasing_sent_to')} ${
              props.data.details.paymentOption.details?.controlledPurchasingDetails?.purchaser?.emailAddress
            }
            `}
          </span>
        </div>
      ) : (
        <span className={styles.title}>
          {getLabel(props.data.labels, 'order')} {props.data.details?.orderReference}
        </span>
      )}
      <OrderList
        labels={props.data.labels}
        orders={[
          {
            orderId: Number(props.orderId),
            dateOrdered: props.data.details?.dateOrdered,
            orderReference: Number(props.data.details?.orderReference),
            orderReferencePersonal: props.data.details?.orderReferencePersonal,
            totalDisplayValue: props.data.details?.summary.grandTotal.price.displayValue,
          } as OrderListOrder,
        ]}
        displayOnly
      />
      {props.data.details && (
        <DetailsAccordion
          labels={props.data.labels}
          title={getLabel(props.data.labels, 'delivery_invoice_payment_details')}
          isOpenByDefault={true}
          disableToggle={props.isDownload}>
          <DeliveryDetails
            contextInfo={props.contextInfo}
            labels={props.data.labels}
            orderNum={props.data.details.orderReference}
            deliveryAddress={props.data.details.deliveryAddress}
            contactDetails={props.data.details.contactDetails}
            isDeliveryAddressSameAsBillingAddress={props.data.details.isDeliveryAddressSameAsBillingAddress}
            invoiceAddress={props.data.details.billingAddress}
            paymentMethod={props.data.details.paymentOption}
            isControlledPurchasing={props.data.details?.paymentOption?.details?.controlledPurchasingDetails !== undefined}
          />
        </DetailsAccordion>
      )}
      <DetailsAccordion
        labels={props.data.labels}
        title={getLabel(props.data.labels, 'order_summary')}
        isOpenByDefault={true}
        disableToggle={props.isDownload}>
        {props.data.details?.parcels.map((product) => (
          <OrderSummaryItem
            labels={props.data.labels}
            parcelItem={product}
            orderRef={props.data.details?.orderReference ?? ''}
            isDownload={props.isDownload}
            onAddToBasketCallback={setCurrentProduct}
            imageLoadedCallback={() => setNumImagesLoaded((n) => n + 1)}
          />
        ))}
      </DetailsAccordion>
      <div className={styles['summary-total-container']}>
        <div className={styles['summary-total']}>
          <div className={styles.column}>
            <span className={styles['num-items']}>
              {props.data.details?.parcels.length} {getLabel(props.data.labels, 'item_s')}
            </span>
            <span>{getLabel(props.data.labels, 'delivery')}</span>
            {props.data.details?.summary.surcharges.map((surcharge) => {
              return <div className={styles['summary-info']}>{surcharge.label}</div>;
            })}
            <span className={styles.total}>{getLabel(props.data.labels, 'total')}</span>
          </div>
          <div className={styles.column}>
            <span>{props.data.details?.summary.netTotal.price.displayValue}</span>
            <span>{props.data.details?.summary.deliveryCharge.price.displayValue}</span>
            {/**Surcharges */}
            {props.data.details?.summary.surcharges.map((surcharge) => {
              let cost = <span className={styles.cost}>{surcharge.price.displayValue}</span>;

              return <div className={styles['summary-info']}>{cost}</div>;
            })}

            <span className={styles.total}>{props.data.details?.summary.grandTotal.price.displayValue}</span>
          </div>
        </div>
      </div>
      {!props.isDownload && (
        <div className={styles.footer}>
          {backToOrdersButton}
          <div className={styles['footer-buttons']}>
            <Button
              text={getLabel(props.data.labels, 'download')}
              id='download'
              buttonType='ghost'
              href={`/user/myaccount/printOrder/${props.orderId}`}
              target='_blank'
              onClick={() => onClickFooterBtn('Download order button')}
            />
            <Button
              text={getLabel(props.data.labels, 'reorder_all')}
              id='reorder_all'
              buttonType='primary'
              onClick={onReorderAllClick}
              icon={isReorderingAll ? 'circular-loader' : undefined}
              iconPosition='right'
              disabled={isReorderingAll}
            />
          </div>
        </div>
      )}

      {bulkAddToBasketResponse && (
        <BulkAddResultModal
          labels={props.data.labels}
          bulkAddToBasketResult={bulkAddToBasketResponse}
          closeCallback={() => setBulkAddToBasketResponse(undefined)}
          backToOrdersCallback={() => setBulkAddToBasketResponse(undefined)}
          viewBasketCallback={() => {
            if (location.pathname !== '/basket') window.location.href = '/basket';
          }}
        />
      )}

      {currentProduct && (
        <ConfirmationModal
          labels={props.data.labels}
          confirmationModalTitle={getLabel(props.data.labels, 'added_to_basket')}
          title={currentProduct.product.product.title.title}
          details={
            <p className={`snippet ${styles.quantity}`}>
              {getLabel(props.data.labels, 'quantity_added').replace('[quantity]', (currentProduct.quantity ?? '').toString())}
            </p>
          }
          imageUrl={currentProduct.product.product.title.primaryImageUrl}
          viewButtonHref={'/basket'}
          viewButtonText={getLabel(props.data.labels, 'view_basket')}
          closeCallback={() => setCurrentProduct(undefined)}
        />
      )}
    </div>
  );

  function onClickFooterBtn(eventInfo: string) {
    const attributes: any = { __Type: 'buttonTracker', __Info: eventInfo, __Result: props.data.details?.orderReference.toString() };
    clickEvent(attributes);
  }

  function onReorderAllClick() {
    setIsReorderingAll(true);

    onClickFooterBtn('Reorder all button');

    myAccountOrdersService
      .bulkAddToBasket({
        lines: props.data.details?.parcels.map((p) => {
          return { stockCode: p.product.title.stockCode, quantity: p.quantity };
        }),
        requestId: props.data.config.topicIdentifier,
      })
      .then((res) => {
        setBulkAddToBasketResponse(res);
        setIsReorderingAll(false);

        //update basket total in header
        headerService.getBasketTotal(userDispatch);
      });
  }
};
