import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useRegistrationPageQuery } from '../../generated/graphql';
import { sanitizeRelativeUrl } from '../../rs-emd-ui-modules/src';
import { RegistrationPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo, Locale } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { customerService } from '../../services/customer-service';

type RegistrationPageWrapperProps = {
  contextInfo: IContextInfo;
};

export const RegistrationWrapper: FunctionComponent<RegistrationPageWrapperProps> = (props: RegistrationPageWrapperProps) => {
  let location = useLocation();

  const redirectionURL = new URLSearchParams(location.search).get('redirectTo');

  const { loading, error, data } = useRegistrationPageQuery({
    variables: {},
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  // if user is already logged in
  else if (!data.registrationPage.isSuccess) {
    if (redirectionURL) return <Redirect to={sanitizeRelativeUrl(redirectionURL)} />;
    return <Redirect to='/user/profile' />;
  }

  return (
    <RegistrationPage
      data={data.registrationPage}
      isIndia={props.contextInfo.locale === Locale.In}
      customerService={customerService}
      redirectionURL={redirectionURL ? sanitizeRelativeUrl(redirectionURL) : undefined}
    />
  );
};

export default RegistrationWrapper;
