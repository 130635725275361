import React, { FunctionComponent } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { OrderDetailsPage, useOrderDetailsPageQuery } from '../../../generated/graphql';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../../rs-emd-ui-modules/src/models/master/context-info.model';
import { OrderDetails } from '../../../rs-emd-ui-modules/src/components/user/my-account/my-account-orders/order-details/order-details-component';

type MyAccountPrintOrderDetailsWrapperProps = {
  contextInfo: IContextInfo;
};

export const MyAccountPrintOrderDetailsWrapper: FunctionComponent<MyAccountPrintOrderDetailsWrapperProps> = (
  props: MyAccountPrintOrderDetailsWrapperProps
) => {
  const { orderId } = useParams<{ orderId?: string }>();

  const { loading, error, data } = useOrderDetailsPageQuery({
    variables: { orderId: Number(orderId ?? '') },
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.orderDetailsPage.isSuccess) {
    return <Redirect to={`/user/login?redirectTo=/user/myaccount/order/${orderId}`} />;
  } else
    return (
      <OrderDetails
        orderId={Number(orderId)}
        data={data.orderDetailsPage as OrderDetailsPage}
        contextInfo={props.contextInfo}
        isDownload={true}
      />
    );
};

export default MyAccountPrintOrderDetailsWrapper;
