import loadable from '@loadable/component';

// Product List Page
const ProductListPage = loadable(() => import(/* webpackChunkName: "plp" */ './product-list-page-component'), {
  resolveComponent: (components) => components.ProductListPage,
});

// Title Area
const TitleArea = loadable(() => import(/* webpackChunkName: "plp" */ './title-area/title-area-component'), {
  resolveComponent: (components) => components.TitleArea,
});

// Page Controls
const PageSizeControls = loadable(
  () => import(/* webpackChunkName: "plp" */ './page-controls/page-size-controls/page-size-controls-component'),
  {
    resolveComponent: (components) => components.PageSizeControls,
  }
);
const Pagination = loadable(() => import(/* webpackChunkName: "plp" */ './page-controls/pagination/pagination-component'), {
  resolveComponent: (components) => components.Pagination,
});

// Product Listing
const ListView = loadable(() => import(/* webpackChunkName: "plp" */ './list-view/list-view-component'), {
  resolveComponent: (components) => components.ListView,
});
const ListProductCard = loadable(() => import(/* webpackChunkName: "plp" */ './list-view/list-product-card/list-product-card-component'), {
  resolveComponent: (components) => components.ListProductCard,
});

// Filters
const FilterArea = loadable(() => import(/* webpackChunkName: "plp" */ './filters/filter-area/filter-area-component'), {
  resolveComponent: (components) => components.FilterArea,
});
const FilterModal = loadable(() => import(/* webpackChunkName: "plp" */ './filters/filter-modal/filter-modal-component'), {
  resolveComponent: (components) => components.FilterModal,
});
const AppliedFilters = loadable(() => import(/* webpackChunkName: "plp" */ './filters/applied-filters/applied-filters-component'), {
  resolveComponent: (components) => components.AppliedFilters,
});
const FilterList = loadable(() => import(/* webpackChunkName: "plp" */ './filters/filter-list/filter-list-component'), {
  resolveComponent: (components) => components.FilterList,
});

// SRP
const CategoriesArea = loadable(() => import(/* webpackChunkName: "plp" */ './categories-area/categories-area-component'), {
  resolveComponent: (components) => components.CategoriesArea,
});

export {
  ProductListPage,
  TitleArea,
  PageSizeControls,
  ListView,
  ListProductCard,
  FilterArea,
  FilterModal,
  AppliedFilters,
  FilterList,
  Pagination,
  CategoriesArea,
};
