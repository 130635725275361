import React, { FunctionComponent } from 'react';
import { Badge, Button } from 'rs-emd-ui-atoms';
import { formatDate } from '../../../../../../helpers/date.utils';
import { getLabel } from '../../../../../../helpers/html.utils';
import { ILabel } from '../../../../../../models/common/label.model';
import styles from './order-list-row-component.module.scss';

export type OrderListRowProps = {
  labels?: ILabel[];
  orderId: number;
  dateOrdered: string;
  orderReference: number;
  orderReferencePersonal?: string;
  totalDisplayValue: string;
  showViewDetails?: boolean;
  isControlledPurchasing: boolean;
  onClickViewDetails?: (orderRef: number) => void;
};

export const OrderListRow: FunctionComponent<OrderListRowProps> = (props: OrderListRowProps) => {
  const viewDetailsUrl = `/user/myaccount/order/${props.orderId}`;

  function getDetail(label?: string, value?: string, total?: boolean) {
    return (
      <div className={styles.detail}>
        <span className={`${styles['detail-header']} ${total ? styles['detail-total'] : ''}`}>{label}</span>
        <span className={total ? styles['detail-total'] : styles['detail-value']}>{value}</span>
      </div>
    );
  }
  const controlledPurchasingBadge = props.isControlledPurchasing && (
    <Badge colour='green'>{getLabel(props.labels, 'controlled_purchasing')}</Badge>
  );

  const gteTabletRow = (
    <>
      <span>{formatDate(new Date(props.dateOrdered))}</span>
      <span>{props.orderReference}</span>
      <span>{props.orderReferencePersonal && <>{props.orderReferencePersonal}</>}</span>
      <span className={styles.total}>{props.totalDisplayValue}</span>
      {props.showViewDetails && (
        <div className={styles['end-section']}>
          {controlledPurchasingBadge}

          <Button
            buttonType='ghost'
            text={getLabel(props.labels, 'view_details')}
            iconPosition='right'
            icon='chev-right'
            href={viewDetailsUrl}
            onClick={() => props.onClickViewDetails && props.onClickViewDetails(props.orderReference)}
            className={styles['view-details-gte-desktop']}
          />
          <Button
            buttonType='link'
            icon='show'
            href={viewDetailsUrl}
            onClick={() => props.onClickViewDetails && props.onClickViewDetails(props.orderReference)}
            className={styles['view-details-lte-tablet']}
          />
        </div>
      )}
    </>
  );

  const mobileRow = (
    <>
      <div className={styles['detail-container']}>
        {controlledPurchasingBadge}
        {getDetail(getLabel(props.labels, 'date_ordered'), formatDate(new Date(props.dateOrdered)))}
        {getDetail(getLabel(props.labels, 'order_no'), (props.orderReference ?? '').toString())}
        {getDetail(getLabel(props.labels, 'your_order_no'), props.orderReferencePersonal)}
        {getDetail(getLabel(props.labels, 'total'), props.totalDisplayValue, true)}
      </div>
      {props.showViewDetails && (
        <Button
          buttonType='link'
          icon='show'
          href={viewDetailsUrl}
          onClick={() => props.onClickViewDetails && props.onClickViewDetails(props.orderId)}
          className={styles['view-details-lte-tablet']}
        />
      )}
    </>
  );

  return (
    <div>
      <div className={`${styles.container} ${styles['gte-tablet']}`}>{gteTabletRow}</div>
      <div className={`${styles.container} ${styles.mobile}`}>{mobileRow}</div>
    </div>
  );
};

export default OrderListRow;
