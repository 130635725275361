export interface IStockAvailability {
  quantityInStock?: number | null;
  availabilityDate?: Date | null;
}

export interface IStock {
  statusCode: StockStatus;
  title: string;
  stockMessage: string;
  dtpStockMessages?: string[] | null;
  infoMessage: string;
  isUsingDTP: boolean;
  denyBackOrder?: boolean | null;
  availabilityDate?: Date | null;
  stockAvailability?: IStockAvailability[] | null;
  includesDate: boolean;
}

export enum StockStatus {
  Backordered = 'BACKORDERED',
  Discontinued = 'DISCONTINUED',
  Instock = 'INSTOCK',
  StatusUnavailable = 'STATUS_UNAVAILABLE',
  CurrentlyUnavailable = 'CURRENTLY_UNAVAILABLE',
  CurrentlyUnavailableBeingDiscontinued = 'CURRENTLY_UNAVAILABLE_BEING_DISCONTINUED',
  CurrentlyUnavailableBeingRemoved = 'CURRENTLY_UNAVAILABLE_BEING_REMOVED',
  CurrentlyUnavailableSupplyShortage = 'CURRENTLY_UNAVAILABLE_SUPPLY_SHORTAGE',
  Error = 'ERROR',
  InStockSupplyShortage = 'IN_STOCK_SUPPLY_SHORTAGE',
  LastStockBeingDiscontinued = 'LAST_STOCK_BEING_DISCONTINUED',
  LastStockBeingRemoved = 'LAST_STOCK_BEING_REMOVED',
  LimitedStock = 'LIMITED_STOCK',
  OutOfStock = 'OUT_OF_STOCK',
  OutOfStockSupplyShortage = 'OUT_OF_STOCK_SUPPLY_SHORTAGE',
  Removed = 'REMOVED',
  StockedByManufacturer = 'STOCKED_BY_MANUFACTURER',
  Preorder = 'PREORDER',
}
