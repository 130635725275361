import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IContextInfo } from '../../../rs-emd-ui-modules/src/models/master/context-info.model';
import ProfileWrapper from '../profile-wrapper-component';
import ResetPasswordWrapper from '../reset-password-wrapper-component';
import MyAccountOrderDetailsWrapper from './my-account-order-details-wrapper-component';
import MyAccountOrdersWrapper from './my-account-orders-wrapper-component';
import MyAccountOverviewWrapper from './my-account-overview/my-account-overview-wrapper-component';

type RouterProps = {
  contextInfo: IContextInfo;
};

export const MyAccountRouter: FunctionComponent<RouterProps> = (props: RouterProps) => {
  return (
    <Switch>
      <Route path='(.*)/user/myaccount/resetPassword' exact render={() => <ResetPasswordWrapper enableMyAccount={true} />}></Route>
      <Route path='(.*)/user/myaccount' exact render={() => <MyAccountOverviewWrapper />}></Route>
      <Route
        path='(.*)/user/profile'
        exact
        render={() => <ProfileWrapper contextInfo={props.contextInfo} enableMyAccount={true} />}></Route>
      <Route path='(.*)/user/myaccount/orders' exact render={() => <MyAccountOrdersWrapper />}></Route>
      <Route
        path='(.*)/user/myaccount/order/:orderId'
        render={() => <MyAccountOrderDetailsWrapper contextInfo={props.contextInfo} />}></Route>
      <Route
        path='*'
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 404;
          return <Redirect to='/error404/error404' />;
        }}
      />
    </Switch>
  );
};
